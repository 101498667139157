import { model } from '@telekomconsalting/dex-guru-model'
import BigNumber from 'bignumber.js'

import { TraderProfileState } from '../reducers/traderProfileReducer'

// Model should match https://0x.org/docs/api#response-1
export interface QuoteResponse {
  price: string
  guaranteedPrice: string
  to: string
  data: string
  value: string
  gasPrice: string
  gas: string
  estimatedGas: number
  protocolFee: string
  minimumProtocolFee: string
  buyAmount: BigNumber
  sellAmount: BigNumber
  sources: Source[]
  buyTokenAddress: model.Address
  sellTokenAddress: model.Address
  allowanceTarget?: string

  // Fields that are not in the table model above but in examples (doc is incomplete)
  from?: string
}

export interface Source {
  proportion: number
  name: string
}

export interface Receipt {
  transactionHash: string
}

export enum GasFeeType {
  instant = 'instant',
  fast = 'fast',
  overkill = 'overkill',
}

export interface GasPrice {
  fast: BigNumber
  instant: BigNumber
  overkill: BigNumber
}

export interface GasParamsEIP1559 {
  baseFee: BigNumber
  maxFee: BigNumber
  maxPriorityFee: BigNumber
}

export interface GasPriceEIP1559 {
  fast: GasParamsEIP1559
  instant: GasParamsEIP1559
  overkill: GasParamsEIP1559
}

export type ZeroXWrapperGasApiResponseItemEIP1559 = {
  source: string // 'DEXGURU'
  timestamp: 1643958449
  'eip-1559': {
    fast: GasParamsEIP1559 // { maxFee: 35877800012; baseFee: 22; maxPriorityFee: 35877799968 }
    instant: GasParamsEIP1559 // { maxFee: 44309500019; baseFee: 22; maxPriorityFee: 44309499975 }
    overkill: GasParamsEIP1559 // { maxFee: 51550000019; baseFee: 22; maxPriorityFee: 51549999975 }
  }
}

export interface CoingeckoLinks {
  chat_url: Array<string>
  telegram_channel_identifier: string
  homepage: Array<string>
  twitter_screen_name: string
}
// Mapped only properties that are used for DYOR
export interface Coingecko {
  links: CoingeckoLinks
  market_cap_rank: number
  market_data: {
    market_cap_rank: number
    market_cap: {
      usd: number
    }
    total_supply: number
    circulating_supply: number
    fully_diluted_valuation: {
      usd: number
    }
  }
}

export type Currency = 'USD' | 'ETH'

export type OmniboxDropdownOption = 'volume' | 'liquidity' | 'price' | 'value'

export interface Screener {
  casual: number
  heavy: number
  medium: number
}

export interface TotalTradesAmount {
  usd: BigNumber
  eth: BigNumber
}

export type ZeroXGasApiResponseItem = {
  source: string // 'MEDIAN'
  timestamp: number //  1625749580
  instant: number // 5000000000
  fast: number // 5000000000
  standard: number // 5000000000
  low: number // 5000000000
}

export type ZeroXGasApiResponse = {
  result: (ZeroXGasApiResponseItem | ZeroXWrapperGasApiResponseItemEIP1559)[]
}

export interface UsdEthPricePair {
  usd: number
  eth: number
}

export interface TradeBarData {
  max: UsdEthPricePair
  min: UsdEthPricePair
  total: TotalTradesAmount
}

export interface Mint {
  add: boolean
}

export interface Burn {
  add: boolean
}

export type TraderProfileArgs = TraderProfileState

export enum FullSidebarsModifierValues {
  tokenProfileTransactions = 'token-profile-transactions',
  profileTradingHistory = 'profile-trading-history',
  poolActivity = 'pool-activity',
  tradingHistory = 'trading-history',
}

export type FullSidebarsModifier =
  | 'profile-trading-history'
  | 'trading-history'
  | 'pool-activity'
  | FullSidebarsModifierValues.tokenProfileTransactions

export interface QueryFee {
  feeRecipient?: string
  buyTokenPercentageFee?: number
}

export interface PairsItem {
  id?: string
  AMM?: model.AMM
  network: model.TokenNetwork
  blockNumber?: number
  ticker?: string
  name?: string | null
  description?: string | null
  token0?: {
    address?: model.Address
    name?: string
    symbol?: string
    decimals?: number
    logoURI?: string
  }
  token1?: {
    address?: model.Address
    name?: string
    symbol?: string
    decimals?: number
    logoURI?: string
  }
  reserve0?: number
  reserve1?: number
  token0Price?: number
  token1Price?: number
  token0PriceETH?: number
  token1PriceETH?: number
  token0PriceUSD?: number
  token1PriceUSD?: number
  token0PriceChange24hETH?: number
  token1PriceChange24hETH?: number
  token0PriceChange24hUSD?: number
  token1PriceChange24hUSD?: number
  token0ReserveETH?: number
  token1ReserveETH?: number
  token0ReserveUSD?: number
  token1ReserveUSD?: number
  volume24hETH?: number
  volume24hUSD?: number
  volumeChange24h?: number
  liquidityETH?: number
  liquidityUSD?: number
  liquidityChange24h?: number
  txns24h?: number
  txns24hChange?: number
  walletAddress?: model.Address
  walletCategory?: string
  transactionBlockNumber?: number
  verified?: boolean
  pairIndex?: number
  feeTier?: number
}

export type PairsData = PairsItem[]
export interface PoolItem {
  id: model.TokenId
  amm: string
  network: model.TokenNetwork
  tokenAddresses: model.Address[]
  symbols: string[]
  logoURIs: string[]
  feeTier?: number
  liquidityNative?: number
  liquidityStable?: number
  volume24hNative?: number
  volume24hStable?: number
}

export type PoolsData = PoolItem[]

export interface PoolsOptions {
  amm?: model.AMM
  network?: model.TokenNetwork
  sort_by?: string
  order?: string
  range_field?: string
  range_gt?: number
  range_lt?: number
  range_gte?: number
  range_lte?: number
  field?: string
  value?: string
  id?: string
  limit?: number
}

export interface OgDynamicMetadata {
  title: string
  description: string
  ogTitle: string
  ogDescription: string
  twitterTitle: string
  twitterDescription: string
}

export interface OgStaticMetadata {
  url: string
  image?: string
  imageWidth?: string
  imageHeight?: string
  twitterCard?: 'summary_large_image'
  twitterImage?: string
}

export interface AMMModel {
  id: string
  AMM: string
  network: model.TokenNetwork
  timestamp: number
  priceStable: number
  priceNative: number
  priceStableChange: number
  priceNativeChange: number
  volumeStable: number
  volumeNative: number
  volumeStableChange: number
  volumeNativeChange: number
  liquidityStable: number
  liquidityNative: number
  liquidityStableChange: number
  liquidityNativeChange: number
  transactions: number
  transactionsChange: number
}

export interface TopCryptosTotals {
  totalMarketCap?: number
  totalMarketCapChange24h?: number
  marketCap?: number
  marketCapChange24h?: number
  tradingVolume24h?: number
  tradingVolumeChange24h?: number
  liquidity?: number
  liquidityChange24h?: number
  tokens?: number
  tradingPairs?: number
}

export const topCryptosAssets: Record<string, string> = {
  all: 'all',
  new: 'new_listed',
  gainers: 'gainers',
  losers: 'losers',
}

export type EthLog = { [key: string]: string }

export interface Amounts {
  fromAmount?: BigNumber
  toAmount?: BigNumber
  tipsAmount?: BigNumber
}

export interface ChainStatus {
  id: number
  name: string
  last_indexed_block: number
  last_indexed_block_timestamp: number
  node_status: 'ERROR' | 'OK'
}

export interface NotificationEvent {
  body: string
  timestamp: string
  id: model.TokenId
  address: model.Address
  network: model.TokenNetwork
  delta: number
  amountUSD: number
  seen?: boolean
}

export interface TokenUriResponse {
  id: number
  name: string
  description: string
  external_url: string
  image: string
}

export interface TrackInfoData {
  type: string
  data?: unknown
}

export interface Trade {
  id: string
  amount0In: number
  token0Address: model.Address
  token1Address: model.Address
  amountUSD: number
  amountETH: number
  AMM: model.AMM
  number: number
  network: model.TokenNetwork
  transactionAddress: string
  amount0: number
  token0Symbol: string
  amount1: number
  token1Symbol: string
  walletAddress: string
  timestamp: number
  amount0Out: number
  amount1Out: number
  amount1In: number
  walletCategory: model.TraderCategoryName
  token0PriceUSD: number
  token1PriceUSD: number
  token0PriceETH: number
  token1PriceETH: number
}

export enum ErrorsValidation {
  invalidAddress = 'invalid Address',
  invalidTokenId = 'tokenId should be [address]-[network] shape',
}

export type FinancialValue = string | number | BigNumber
export enum UserWalletCategory {
  Noob = 'Noob',
  Casual = 'Casual', // tortoise
  Medium = 'Medium', // shark
  Heavy = 'Heavy', // whale
  Bot = 'Bot', // robot
  Unknown = 'Unknown', // if payload was undefined/null
}

export enum TokenSpenders {
  oneInch = 'oneInch',
  zeroX = 'zeroX',
}
