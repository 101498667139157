export interface AlertDefinition {
  threshold?: number
  address?: string
  chain_id?: string
  price?: string
}

export enum AlertKind {
  FavoriteTokenPriceChange = 'FavoriteTokenPriceChange',
  TokenPriceChange = 'TokenPriceChange',
  TokenPriceTarget = 'TokenPriceTarget',
}

export interface AddAlertResponse {
  id: string
}

export interface AlertUpdateBody {
  name: string
  kind?: AlertKind
  definition: AlertDefinition
}

export interface AlertBody {
  name: string
  kind: AlertKind
  definition: AlertDefinition
}

export interface Alert extends AlertBody {
  id: string
  account_id: number
  created_at: string
  updated_at: string
}

export interface Notification {
  id: string
  created_at: string
  data: {
    price_change: number
    token: {
      price: number
      address: string
      chain_id: number
    }
  }
}

export interface AlertsResponse {
  data: Alert[]
  offset: number
  limit: number
  total: number
}

export interface NotificationResponse {
  notifications: Notification[]
  kind: NotificationKind
  max_notifications: number
  last?: LastNotification
}

export interface LastNotification {
  notification_id: string
  created_at: string
}

export enum NotificationKind {
  TokenPriceChanged = 'TokenPriceChanged',
}

export type MarkedNotificationResponse = LastNotification
export interface NotificationMessage {
  channel_id: string
  update: Notification
}
export interface LastNotificationMessage {
  channel_id: string
  update: LastNotification
}
