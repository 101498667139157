import { model } from '@telekomconsalting/dex-guru-model'

import { ResolutionString } from '../charting_library'
import { TokenNetwork, TokenV3, TraderBalanceRowData } from './tokenModel'

export interface TokenChartSource {
  type: string
  id: string
  state: unknown
  zorder: number
  haStyle: { studyId: string }
  renkoStyle: { studyId: string }
  pbStyle: { studyId: string }
  kagiStyle: { studyId: string }
  pnfStyle: { studyId: string }
  rangeStyle: { studyId: string }
}

export interface TokenChartAxis {
  state: unknown
  sources: string[]
}

export interface ChartTooltipOptions {
  isAmm?: boolean
  token?: TraderBalanceRowData
  currency?: string
  isSorted?: boolean
  skipEmpty?: boolean
  isCategory?: boolean
}

export type TokenChartSettings = Pick<
  TokenV3,
  | 'name'
  | 'network'
  | 'id'
  | 'logoURI'
  | 'symbols'
  | 'priceUSDChange24h'
  | 'volume24hUSD'
  | 'priceUSD'
> & {
  tokenTitle: string
  uuid?: string
  chartSettings?: TokenChartData
  chartLastInterval?: ResolutionString
}

export type ConfigChart = {
  period: number
  interval: string
}

export type ChartRowCategoryV3 = {
  timestamp?: number
  casual?: {
    txCount: number
    volume: number
  }
  heavy?: {
    txCount: number
    volume: number
  }
  medium?: {
    txCount: number
    volume: number
  }
  bot?: {
    txCount: number
    volume: number
  }
  noob?: {
    txCount: number
    volume: number
  }
}

export type ChartDataCategoryV3 = ChartRowCategoryV3[]

export interface TraderBalanceChartData {
  timestamp: number
  value: number
  price: number
}

export interface TokenChartPane {
  sources: TokenChartSource[]
  leftAxisesState: TokenChartAxis[]
  rightAxisesState: TokenChartAxis[]
  overlayPriceScales: {
    Ubr434: {
      id: string
      m_priceRange: { m_maxValue: number; m_minValue: number }
      m_isAutoScale: boolean
      m_isPercentage: boolean
      m_isIndexedTo100: boolean
      m_isLog: boolean
      m_isLockScale: boolean
      m_isInverted: boolean
      m_height: number
      m_topMargin: number
      m_bottomMargin: number
      alignLabels: boolean
    }
  }
  stretchFactor: number
  mainSourceId: string
  priceScaleRatio: number | null
}

export interface TokenChartData {
  layout: string
  charts: TokenChart
}

export interface TokenChart {
  panes: TokenChartPane[]
  timeScale: { m_barSpacing: number; m_rightOffset: number }
  chartProperties: {
    paneProperties: {
      backgroundType: string
      background: string
      backgroundGradientStartColor: string
      backgroundGradientEndColor: string
      vertGridProperties: { color: string; style: number }
      horzGridProperties: { color: string; style: number }
      crossHairProperties: {
        color: string
        style: number
        transparency: number
        width: number
        'color:': string
      }
      topMargin: number
      bottomMargin: number
      axisProperties: {
        autoScale: boolean
        autoScaleDisabled: boolean
        lockScale: boolean
        percentage: boolean
        percentageDisabled: boolean
        indexedTo100: boolean
        log: boolean
        logDisabled: boolean
        alignLabels: boolean
        isInverted: boolean
      }
      legendProperties: {
        showStudyArguments: boolean
        showStudyTitles: boolean
        showStudyValues: boolean
        showSeriesTitle: boolean
        showSeriesOHLC: boolean
        showLegend: boolean
        showBarChange: boolean
        showBackground: boolean
        backgroundTransparency: number
        wrapText: boolean
      }
    }
    scalesProperties: {
      backgroundColor: boolean
      lineColor: string
      textColor: string
      fontSize: number
      scaleSeriesOnly: boolean
      showSeriesLastValue: boolean
      seriesLastValueMode: number
      showSeriesPrevCloseValue: boolean
      showStudyLastValue: boolean
      showSymbolLabels: boolean
      showStudyPlotLabels: boolean
      showBidAskLabels: boolean
      showPrePostMarketPriceLabel: boolean
      showFundamentalNameLabel: boolean
      showFundamentalLastValue: boolean
      barSpacing: number
      showCurrency: boolean
      showUnit: boolean
    }
    chartEventsSourceProperties: {
      visible: boolean
      futureOnly: boolean
      breaks: { color: string; visible: number; style: number; width: number }
    }
    tradingProperties: {
      showPositions: boolean
      showOrders: boolean
      showExecutions: boolean
      extendLeft: boolean
      lineLength: number
      lineWidth: number
      lineStyle: number
    }
    priceScaleSelectionStrategyName: string
  }
  lineToolsGroups: { groups: unknown[] }
  version: number
  timezone: string
  sessions: {
    properties: {
      graphics: {
        backgrounds: {
          outOfSession: { color: string; transparency: number; visible: boolean }
          preMarket: { color: string; transparency: number; visible: boolean }
          postMarket: { color: string; transparency: number; visible: boolean }
        }
        vertlines: { sessBreaks: { color: string; style: number; visible: boolean; width: number } }
      }
    }
  }
  shouldBeSavedEvenIfHidden: boolean
  chartId: string
}

export enum FormatDateChartType {
  numeric = 'numeric',
  twoDigit = '2-digit',
  long = 'long',
  short = 'short',
  narrow = 'narrow',
}

export enum PeriodChartType {
  all_time = 'all_time',
  month = 'month',
  week = 'week',
  day = 'day',
}

export interface ChartRow {
  AMM: model.AMM // 'pancakeswap_v2'
  dailyTxns: number // 92229
  dailyVolumeUSD: number // 85785656.08746852
  date: number // 1620864000
  id: string // '0x2170ed0880ac9a755fd29b2688956bd959f933f8-1620864000'
  liquidity: number // 21244317.63019543
  network: TokenNetwork // 'bsc'
  totalLiquidityUSD: number // 21244317.63019543
  volume: number // 85785656.08746852
  priceUSD: number
}

export type ChartData = ChartRow[]

export type ChartRowV3 = {
  id: string
  dailyTxns: number
  date: number
  currency: 'USD' | 'ETH' | 'S' | 'N'
  dailyVolume: number
  totalLiquidity: number
  AMM: model.AMM
  network: TokenNetwork
  price: number
}

export type ChartDataV3 = ChartRowV3[]
