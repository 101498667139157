import { model } from '@telekomconsalting/dex-guru-model'

import { FilterFields } from '../components/FilterForm/FilterForm.types'

export enum TransactionTypes {
  mint = 'mint',
  burn = 'burn',
  swap = 'swap',
  transfer = 'transfer',
}

export enum OrderFilterTypes {
  desc = 'desc',
  asc = 'asc',
}

export enum Filter {
  up = 'up',
  favorite = 'favorite',
  trending = 'trending',
  gainers = 'gainers',
  losers = 'losers',
  recent = 'recent',
  walletTab = 'wallet-tab',
}

export enum DateFilterTypes {
  custom = 'custom',
  all = 'all',
  last24h = '0',
  yesterday = '1',
  last30Days = '30',
  month = 'month',
}

export enum TokenStatuses {
  all = 'all',
  buy = 'buy',
  sell = 'sell',
}

export enum Operators {
  lt = 'lt',
  gt = 'gt',
  eq = 'eq',
}

export type FilterTradeType = 'buy' | 'sell'

export enum TraderCategoryName {
  casual = 'casual',
  medium = 'medium',
  heavy = 'heavy',
  bot = 'bot',
}

export enum LiquidityProviderCategoryName {
  rooster = 'rooster',
  tiger = 'tiger',
  elephant = 'elephant',
}

export enum FilterSortBy {
  id = 'id',
  address = 'address',
  symbol = 'symbol',
  name = 'name',
  description = 'description',
  txns24h = 'txns24h',
  txns24hChange = 'txns24hChange',
  verified = 'verified',
  decimals = 'decimals',
  volume24h = 'volume24h',
  volume24hUSD = 'volume24hUSD',
  volume24hETH = 'volume24hETH',
  volumeChange24h = 'volumeChange24h',
  liquidityUSD = 'liquidityUSD',
  liquidityETH = 'liquidityETH',
  liquidityChange24h = 'liquidityChange24h',
  priceUSD = 'priceUSD',
  priceETH = 'priceETH',
  priceUSDChange24h = 'priceUSDChange24h',
  priceETHChange24h = 'priceETHChange24h',
  timestamp = 'timestamp',
  blockNumber = 'blockNumber',
  amm = 'amm',
  network = 'network',
}

export interface TradeSizeUsdFilter {
  amount?: number | string
  operator?: Operators
}

export interface DateFilter {
  start_date?: number
  end_date?: number
  // Note this is not part of contract
  // but we pass it around to know the diff
  // between start and end. Backend does not mind it
  period?: DateFilterTypes
}

export interface FilterOptions {
  current_token_id?: model.TokenId
  token_id?: model.TokenId | null
  transaction_types?: TransactionTypes[] | null
  account?: model.Address | null
  second_account?: model.Address | null
  amm?: model.AMM | null
  date?: DateFilter | null
  limit?: number | null
  network?: string | null
  offset?: number | null
  order?: 'asc' | 'desc'
  sort_by?: FilterSortBy
  token_status?: TokenStatuses | null
  trade_size_usd?: TradeSizeUsdFilter | null
  pool_address?: model.Address | null
  wallet_category?: model.Category[] | null
  with_full_totals?: boolean
  transfer_from_address?: model.Address | null
  transfer_to_address?: model.Address | null
}

export const errorMessage: Record<string, string> = {
  account: 'Address has to be at least 42 characters long',
  second_account: 'Address has to be at least 42 characters long',
  pool_address: 'Address has to be at least 42 characters long',
}

export type FilterError = {
  [FilterFields.account]?: string
  [FilterFields.poolAddress]?: string
  [FilterFields.secondAccount]?: string
}

export type FilterDateRecord = {
  period?: DateFilterTypes
  fromDate?: number
  toDate?: number
}

export type TotalValueRecord = {
  operator: Operators
  amount: string
}
